"use strict";

//jsで外部JSファイルを読み込む
function appendScript(URL, charset) {
  var el = document.createElement('script');
  el.setAttribute('type', 'text/javascript');

  if (charset) {
    el.setAttribute('charset', charset);
  } else {
    el.setAttribute('charset', 'UTF-8');
  }

  el.src = URL;
  document.body.appendChild(el);
} //jsで外部CSSファイルを読み込む


function appendCSS(URL) {
  var css = document.createElement('link');
  css.setAttribute('rel', 'stylesheet');
  css.setAttribute('type', 'text/css');
  css.setAttribute('href', URL);
  document.body.appendChild(css);
} //カウントダウンタイマー


var toDoubleDigits = function toDoubleDigits(num) {
  num += "";

  if (num.length === 1) {
    num = "0" + num;
  }

  return num;
};

function countDown(ele, endDate) {
  //console.log(endDate);
  var startDateTime = new Date();
  var endDateTime = new Date(endDate);
  var left = endDateTime - startDateTime;
  var a_day = 24 * 60 * 60 * 1000;
  var d = Math.floor(left / a_day);
  var h = toDoubleDigits(Math.floor(left % a_day / (60 * 60 * 1000)));
  var m = toDoubleDigits(Math.floor(left % a_day / (60 * 1000)) % 60);
  var s = toDoubleDigits(Math.floor(left % a_day / 1000) % 60 % 60);
  var mil = toDoubleDigits(Math.floor(left % a_day / 10) % 100);
  $(ele).html(d + "D" + h + "H" + m + "M" + s + "S" + mil); //setTimeout('countDown('+endDateTime+')', 1);

  setTimeout(function () {
    countDown(ele, endDate);
  }, 1);
} //ul(ol)タグの中身をランダムにする（ele:親要素名,limit:表示件数）


function randomList(ele, limit) {
  var array = [];
  $("li", ele).each(function (e) {
    array.push($(this).html());
  });

  if (!limit) {
    limit = array.length;
  } //console.log('randomlistlimit:'+limit);


  array.sort(function () {
    return Math.random() - Math.random();
  });
  $(ele).empty();

  for (var i = 0; i < limit; i++) {
    $(ele).append('<li>' + array[i] + '</li>');
  }
} //日付エンコード


function dateFormat(date, kugiri) {
  if (!date) {
    var date = new Date();
  } else {
    var date = new Date(date);
  }

  return date.getFullYear() + kugiri + (date.getMonth() + 1) + kugiri + date.getDate();
} //ポイントを含む会員情報を取得してきて表示（カラーミーAPI使用）


function accountData(id) {
  $.getJSONP = function (url, callback, param) {
    return $.ajax({
      url: url,
      dataType: "jsonp",
      success: callback
    });
  }; // $.getJSONP("https://material-klonklonklon.com/api/klon/getPoint.php?id=" + <{$members_id}>, onDataHandler)


  $.getJSONP("https://material-klonklonklon.com/api/klon/getPoint.php?id=" + id, onDataHandler);

  function onDataHandler(response) {
    var name = response.name;
    var point = response.point.toLocaleString();
    var id = response.id;
    $(".js-myName").html(name);
    $(".js-myPoint").html(point);
    $(".js-myID").html(id);
    addCookie('loginFlg', 'login' + id);
  }
} //cookieに追加


function addCookie(cookieName, cookieValue, expires) {
  if (expires) {
    var date = new Date();
    date.setTime(date.getTime() + expires * 60 * 60 * 1000);
  } else {
    var date = 365;
  }

  $.cookie(cookieName, cookieValue, {
    expires: date
  });
} //購入履歴の取得と反映（カラーミーAPI使用）


function personalDataList(url) {
  $.getJSONP = function (url, callback, param) {
    return $.ajax({
      url: url,
      dataType: "jsonp",
      success: callback
    });
  };

  $.getJSONP(url, onDataHandler);

  function onDataHandler(response) {
    var list = '';
    $.each(response, function (index, elem) {
      var id = elem.id;
      var make_date = elem.make_date;
      var delivered = elem.delivered;
      var canceled = elem.canceled;
      var details = elem.details;
      var make_date = new Date(make_date).getTime();
      $.each(details, function (i, e) {
        var pid = e.product_id;
        var name = e.pristine_product_full_name;
        var price = e.price.toLocaleString();
        var pname = name.substring(0, name.indexOf("（"));
        var popt = ''; //オプション名
        //（　＝　%EF%BC%88
        //：　＝　%EF%BC%9A
        //、　＝　%E3%80%81
        //【　＝　%E3%80%90

        if (name.indexOf("（") != -1) {
          //console.log('何文字目：'+name.indexOf("（"));
          var option = name.slice(name.indexOf("（"), name.length);
          option = option.slice(1, -1);
          var options = option.split('、'); //console.log(options);

          popt += '<span>';
          $.each(options, function (num, v) {
            var removerTxt = v.substring(v.indexOf("：") + 1, 0);
            var removerCode = v.substring(v.indexOf("【"), name.length); //console.log('removerCode:'+removerCode);

            if (num > 0) {
              popt += '/';
            }

            popt += v.replace(removerTxt, '').replace(removerCode, '');
          });
          popt += '</span>';
        } else {//console.log('pname:'+pname);
        }

        if (i <= 10) {
          list += '<li data-flg-cancel="' + canceled + '"><figure class="l-mypage__itemBox__img"><a href="?pid=' + pid + '"><img src="//img21.shop-pro.jp/PA01387/268/product/' + pid + '.jpg" alt="' + pname + '"></a></figure><p class="l-mypage__itemBox__price">' + popt + '&yen;' + price + '</p></li>';
        }
      });
    });
    $(".js-api__order").append(list);
  }
} //商品情報の取得（カラーミーAPI使用）
// function getItemData(url){
//     $.getJSONP = function(url,callback,param) {
//         return $.ajax({
//             url:    url,
//             dataType:"jsonp",
//             success:callback
//         });
//     }
//     $.getJSONP(url, onDataHandler)
//     function onDataHandler(response) {
//         $.each(response,function(index, elem){
//             //this.id = elem.id;
//             //this.name = elem.name;
//             console.dir(this);
//         });
//     }
//     //return $.getJSONP(url, onDataHandler);
// }
//メニューボタンなどクリック時にオーバーレイ背景を開閉させる。


function toggleOverray() {
  // var hasFlg = $('body:has(".js-overray__active")').length;
  var hasFlg = document.querySelectorAll('.js-overray__active').length;
  console.log('hasFlg:' + hasFlg);

  if (hasFlg != 0) {
    $('#js-gnav__overray').addClass('js-fadeIn');
    document.documentElement.style.overflow = 'hidden';
  } else {
    $('#js-gnav__overray').removeClass('js-fadeIn');
    document.documentElement.style.overflow = '';
  }
} //メニューボタンなどクリック時に適するメニューコンテンツを表示させる


function navOpen() {
  if ($('.js-navOpenBtn').hasClass('js-active')) {
    $('[data-nav-name]').removeClass('js-active js-overray__active');
  }
} //別ウィンドウで開く(url:リンク先,readFlg:トリガーが必要なもの。input推奨)


function openWindow(url, readFlg) {
  var view = window.open(url, null, "width=820,height=600,scrollbars=yes,status=no,toolbar=no,location=no,menubar=no,directories=no,resizable=yes");
  view.focus();

  if (readFlg != null) {
    readFlg.checked = true;
  }
} //ローディングアイコン表示用


function loadingUP(wrapperEle) {
  if (wrapperEle) {
    var ele = wrapperEle + ' .js-loading';
  } else {
    var ele = '.js-loading';
  }

  $(ele).prepend('<div class="js-loading__inner"><span class="js-loading__spinner"></span></div>');
  $(" .js-loading__inner", ele).fadeIn(300);
} //ローディングアイコン削除用


function loadingDown(wrapperEle) {
  if (wrapperEle) {
    var ele = wrapperEle + ' .js-loading__inner';
  } else {
    var ele = '.js-loading__inner';
  }

  setTimeout(function () {
    //$(ele).fadeOut(300);
    $(ele).remove();
    console.log('complete');
  }, 500);
} //ローディングアイコン表示用（AJAX読込中処理）


function ajaxLoadingUp(ele) {
  $(document).ajaxSend(function () {
    if (!$(ele).find('.js-loading__inner').length) {
      loadingUP(ele);
    }
  });
}
/**********************
cookie利用関数は下記に記述
***********************/
// //ポップアップの設定
// function popUp(){
//     setTimeout(function(){
//         var modal = $(".modal");
//         var opacity = 0.6;
//         var button = $(".close_modal");
//         var limit = 60;//分単位
//         var cookie = $.cookie("modal");
//         if(cookie !== "off"){
//             var overlay = $("<div id='bg_popup'></div>");
//             overlay.css({
//                 "position":"fixed",
//                 "z-index":10500,
//                 "top":0,
//                 "left":0,
//                 "height":100+"%",
//                 "width":100+"%",
//                 "background":"#000000", 
//                 "opacity":opacity
//             });
//             $("body").append(overlay);
//             modal.css("display", "block");
//         }
//         button.click(function(){
//             $(overlay).fadeOut("slow");
//             $(modal).hide();
//             var clearTime = new Date();
//             clearTime.setTime(clearTime.getTime()+(limit*60*1000));
//             $.cookie("modal", "off", {expires:clearTime, path:"/"});
//         });
//         $(".remove_cookie").click(function(){
//             $.removeCookie("modal", {expires:-1, path:"/"});
//             location.reload();
//         });
//         $("#bg_popup").click(function(){
//             $(overlay).fadeOut("slow");
//             $(modal).hide();
//             var clearTime = new Date();
//             clearTime.setTime(clearTime.getTime()+(limit*60*1000));
//             $.cookie("modal", "off", {expires:clearTime, path:"/"});
//         });
//     },5000);
// }
// function pageLoad() {
//     var obj = GetDisplaySize();
//     target = document.getElementById("output");
//     var ele = "ScreenWidth:" + obj.width + "</br>"
//       + "ScreenHeight:" + obj.height + "</br>"
//       + "availWidth:" + obj.availWidth + "</br>"
//       + "availHeight:" + obj.availHeight + "</br>"
//       //+ "systemXDPI:" + obj.systemXDPI + "</br>"
//       //+ "systemYDPI:" + obj.systemYDPI + "</br>"
//       + "pixelDepth:" + obj.pixelDepth + "</br>";
//     target.innerHTML = ele;
//     //console.log(ele);
// }
// function GetDisplaySize() {
//     // 解像度
//     this.width = window.screen.width;
//     this.height = window.screen.height;
//     //有効画面サイズ
//     this.availWidth = window.screen.availWidth;
//     this.availHeight = window.screen.availHeight;
//     //システム解像度
//     // this.systemXDPI = window.screen.systemXDPI;
//     // this.systemYDPI = window.screen.systemYDPI;
//     this.pixelDepth = window.screen.pixelDepth;
//     //console.log(this);
//     return this;
//   }